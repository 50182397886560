<template>
    <div class="full-height-layout fill" data-test="supplierImportProductStepArtwork">
        <product-templates-view
            v-if="supplierProductImportedDetails"
            :parent-product-configuration="productConfiguration"
            class="fill pr-20 pt-10 ml-10"
        />

        <div class="align-right">
            <mercur-button class="btn" :disabled="isSavingProduct" @click="save(true)" data-test="supplierImportProductSkipArtwork">Skip Artwork</mercur-button>
            <mercur-button class="btn btn-yellow" :disabled="isSavingProduct" @click="save">Save and continue</mercur-button>
        </div>
    </div>
</template>

<script>
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import ProductTemplatesView from '../products/ProductTemplatesView'

export default {
    name: 'SupplierImportedProductArtwork',
    mixins: [ SupplierImportedProductMixin ],
    components: { ProductTemplatesView },
    watch: {
        supplierProductImportedDetails () {
            if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.cutofftimeConfiguration) {
                return
            }

            this.initialValues.artworkConfiguration = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.artworkConfiguration || []))
        },
    },
    computed: {
        productConfiguration () {
            if (!this.supplierProductImportedDetails) {
                return {}
            }

            let artworkConfig = this.supplierProductImportedDetails.artworkConfiguration
            if (this.supplierProductImportedDetails.artworkConfiguration && this.supplierProductImportedDetails.artworkConfiguration.artworkSkipped) {
                artworkConfig = []
            }

            return {
                artworkConfiguration: artworkConfig || [],
                attributeConfiguration: {
                    selectedAttributes: this.supplierProductImportedDetails.attributeConfiguration,
                },
                productConfigurationName: this.supplierProductImportedDetails.productName,
            }
        },
        payload () {
            return {
                artworkConfiguration: JSON.parse(JSON.stringify(this.productConfiguration.artworkConfiguration)),
            }
        },
    },
    methods: {
        save (isSkip = false) {
            const onSuccess = () => {
                this.skipCheckForChanges = true
                this.$emit('pushRoute', { name: 'SupplierImportedProductConfirmation' })
            }
            if (isSkip) {
                this.$emit('save', { artworkConfiguration: { artworkSkipped: true }, onSuccess })
            } else {
                this.$emit('save', { ...this.payload, onSuccess })
            }

            this.$watch('isSavingProduct', (isSavingProduct) => {
                if (isSavingProduct) {

                }
            })
        },
        checkForChanges () {
            if (!this.skipCheckForChanges && JSON.stringify(this.initialValues.artworkConfiguration) !== JSON.stringify(this.payload.artworkConfiguration)) {
                return true
            }
            return false
        },
    },
    created () {
        if (!this.supplierProductImportedDetails || !this.supplierProductImportedDetails.cutofftimeConfiguration) {
            return
        }

        let artworkConfig = this.supplierProductImportedDetails.artworkConfiguration
        if (this.supplierProductImportedDetails.artworkConfiguration && this.supplierProductImportedDetails.artworkConfiguration.artworkSkipped) {
            artworkConfig = []
        }
        this.initialValues.artworkConfiguration = JSON.parse(JSON.stringify(artworkConfig || []))
    },
}
</script>
